<template>
  <div class="order-page my-lg-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7">
          <div class="card">
            <div class="card-header">
              <img v-if="origin.includes('tapengine.io')" src="@assets/images/tapengine-logo.png" style="display:block;margin:auto;height:60px;max-width:100%;"/>
              <img v-else-if="origin.includes('getsocialreviews.com')" src="@assets/images/social-review-logo.png" style="display:block;margin:auto;height:60px;max-width:100%;"/>
              <img v-else-if="origin.includes('reviewsurge.co')" src="@assets/images/review-surge-logo.png" style="display:block;margin:auto;height:50px;max-width:100%;"/>
              <img v-else-if="origin.includes('leadmachine.io')" src="@assets/images/lead-machine-logo.png" style="display:block;margin:auto;height:25px;max-width:100%;"/>
              <img v-else-if="origin.includes('socialfusionai.com')" src="@assets/images/social-fusion-logo.png" style="display:block;margin:auto;height:25px;max-width:100%;"/>
              <img v-else-if="origin.includes('instastream.co')" src="@assets/images/insta-stream-logo.png" style="display:block;margin:auto;height:25px;max-width:100%;"/>
              <img v-else src="@assets/images/logo.png" style="display:block;margin:auto;height:60px;max-width:100%;"/>
            </div>
            <div class="card-body p-0">
              <div v-if="currentStep === 1" class="row">
                <div class="col order-form">
                  <h3 class="h5 text-muted mb-1">Create your account</h3>
                  <p class="text-muted mt-1 mb-4">
                    Start generating leads for your business today!
                  </p>
                  <ValidationObserver
                    v-slot="{ handleSubmit, invalid, touched }"
                    ref="orderForm"
                  >
                    <form
                      class="authentication-form"
                      @submit.prevent="handleSubmit(handleRegister)"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <TextInput
                            v-model="credentials.first_name"
                            placeholder="First name"
                            rules="required"
                            name="first_name"
                            icon="user"
                            label="Name"
                          />
                        </div>
                        <div class="col-md-6">
                          <label class="form-control-label text-white">Last Name</label>
                          <TextInput
                            v-model="credentials.last_name"
                            placeholder="Last name"
                            rules="required"
                            name="last_name"
                          />
                        </div>
                      </div>
                      <TextInput
                        v-model="credentials.email"
                        autocomplete="new-account"
                        placeholder="Enter email"
                        rules="required|email"
                        name="email"
                        icon="mail"
                        label="Email Address"
                      />
                      <small class="font-size-11">By providing us with your information you are consenting to the collection and use of your information in accordance with our
                        <span v-if="origin.includes('tapengine.io')">
                          <a href="https://tapengine.io/terms-of-service" target="_blank">Terms of Service</a> and <a href="https://tapengine.io/privacy-policy" target="_blank">Privacy Policy</a>.
                        </span>
                        <span v-if="origin.includes('getsocialreviews.com')">
                          <a href="https://getsocialreviews.com/terms" target="_blank">Terms of Service</a> and <a href="https://getsocialreviews.com/privacy-policy" target="_blank">Privacy Policy</a>.
                        </span>
                        <span v-if="origin.includes('reviewsurge.co')">
                          <a href="https://reviewsurge.co/terms-of-service" target="_blank">Terms of Service</a> and <a href="https://reviewsurge.co/privacy-policy" target="_blank">Privacy Policy</a>.
                        </span>
                        <span v-if="origin.includes('socialfusionai.com')">
                          <a href="https://socialfusionai.com/terms-of-service" target="_blank">Terms of Service</a> and <a href="https://socialfusionai.com/privacy-policy" target="_blank">Privacy Policy</a>.
                        </span>
                        <span v-if="origin.includes('instastream.co')">
                          <a href="https://instastream.co/terms-of-service" target="_blank">Terms of Service</a> and <a href="https://instastream.co/privacy-policy" target="_blank">Privacy Policy</a>.
                        </span>
                        <span v-else>
                          <a href="https://replyengine.io/terms-of-service" target="_blank">Terms of Service</a> and <a href="https://replyengine.io/privacy-policy" target="_blank">Privacy Policy</a>.
                        </span>
                      </small>
                      <b-form-group class="mt-4 mb-1">
                        <b-button
                          variant="primary"
                          class="btn-block"
                          :disabled="loadingRegister || (invalid && touched)"
                          type="submit"
                        >
                          <b-spinner v-if="loadingRegister" small />
                          <span v-else>Sign Up</span>
                        </b-button>
                      </b-form-group>
                      <div class="col-12 text-center mt-3">
                        <p class="text-muted">
                          Already have an account?
                          <router-link
                              :to="{ name: 'login', query: { 'redirect_url': redirectUrl } }"
                              class="text-primary font-weight-bold"
                          >
                            <b>Login</b>
                          </router-link>
                        </p>
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
              <div v-if="currentStep === 2" class="row">
                <div class="col order-form">
                  <h3 class="h5 text-muted mb-1"><span v-if="plan">{{ plan.name }}</span></h3>
                  <p class="text-muted mt-1 mb-4">
                    Enter your payment information below to get immediate access to the software.
                  </p>
                  <ValidationObserver
                      v-slot="{ handleSubmit, invalid, touched }"
                      ref="orderForm"
                  >
                    <form
                        class="authentication-form"
                        @submit.prevent="handleSubmit(handlePayment)"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <TextInput
                              v-model="credentials.first_name"
                              placeholder="First name"
                              rules="required"
                              name="first_name"
                              icon="user"
                              label="Name"
                          />
                        </div>
                        <div class="col-md-6">
                          <label class="form-control-label text-white d-none d-md-inline-block d-lg-inline-block d-xl-inline-block">Last Name</label>
                          <TextInput
                              v-model="credentials.last_name"
                              placeholder="Last name"
                              rules="required"
                              name="last_name"
                          />
                        </div>
                      </div>
                      <TextInput
                          v-model="credentials.email"
                          autocomplete="new-account"
                          placeholder="Enter email"
                          rules="required|email"
                          name="email"
                          icon="mail"
                          label="Email Address"
                      />
                      <AddCreditCard ref="cardForm" />
                      <div v-if="plan" class="row">
                        <div v-if="plan.desc" class="col text-center py-2">{{ plan.desc }}</div>
                      </div>
                      <div class="row">
                        <div class="col text-right pr-5">
                          <h4 class="h5 text-muted">Total Due Today: <strong v-if="plan" class="pl-2">${{ totalPrice.toFixed(2) }}</strong></h4>
                        </div>
                      </div>
                      <b-form-group class="mt-4 mb-1">
                        <b-button
                            variant="primary"
                            class="btn-block"
                            :disabled="loadingRegister || (invalid && touched)"
                            type="submit"
                        >
                          <b-spinner v-if="loadingRegister" small />
                          <span v-else>Submit</span>
                        </b-button>
                      </b-form-group>
                    </form>
                    <p class="text-center mt-3" style="font-size:11px;">
                      We use a secure payment gateway – Stripe – that
                      processes payments on our behalf. We do
                      not store credit or debit card details on our website.
                      All payment data is securely stored by a third-party
                      PCI-compliant payment gateway.
                    </p>
                  </ValidationObserver>
                </div>
              </div>
              <div v-if="currentStep === 3" class="step-3 text-center">
                <h3 class="h3 mt-4 mb-4 text-primary"
                  >Congratulation - Your order has been successfully
                  completed.</h3
                >
                <div class="mb-4">
                  <p class="h6 text-primary">
                    Please check your inbox to get your login credential.
                  </p>
                </div>
                <div class="pb-4 pt-4">
                  <router-link
                    class="btn btn-outline-primary"
                    :to="{ name: 'agency.dashboard' }"
                    >GO TO DASHBOARD</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddCreditCard from '../Common/AddCreditCard'
import moment from 'moment-timezone'
import Vue from 'vue'
import axios from 'axios'

export default {
  components: { AddCreditCard },

  data() {
    return {
      credentials: {
        first_name: '',
        email: '',
        password:'',
        plan: '',
        payment_method: '',
        user_id: null,
        upsells:[]
      },
      origin:window.location.origin,
      currentStep: 1,
      plan: null,
      loadingRegister: false,
      year: moment().format('Y'),
      user:null
    }
  },

  computed: {
    redirectUrl(){
      return window.location.href;
    },
    whitelabelDomain() {
      return this.$store.getters['app/whitelabelDomain']
    },
    plans() {
      return this.$store.getters['subscription/allPlans'] || []
    },
    totalPrice(){
      console.log(this.plan);
      return this.plan.upsells.reduce((accumulator, product) => {
        return accumulator + product.price;
      }, 0) + this.plan.amount;
    }
  },

  mounted() {
    // Check plan
    if (!this.$route.query.plan) {
      this.$router.push('/')
    } else {
      this.credentials.first_name = this.$route.query.first_name
      this.credentials.email = this.$route.query.email
      this.getPlan()
      // Redirect to next page if logged in
      if(this.$store.getters['auth/isLoggedIn']){
        this.user = this.$store.getters['auth/user'];
        this.credentials.first_name = this.user.first_name;
        this.credentials.last_name = this.user.last_name;
        this.credentials.email = this.user.email;
        this.credentials.user_id = this.user.id;
        this.currentStep++;
      }
    }
  },

  methods: {
    nextStep() {
      this.currentStep++
    },

    getPlan() {
      this.$store
        .dispatch('subscription/getPlanByName', this.$route.query.plan)
        .then((res) => {
          console.log(res);
          this.plan = res.data
          this.credentials.plan = res.data.id;
          this.credentials.upsells = this.plan.upsells;
        })
        .catch(() => {
          window.location.replace('/')
        })
    },

    async validateCard() {
      this.loadingRegister = true
      this.credentials.payment_method = null

      await this.$refs.cardForm
        .validate()
        .then((paymentMethod) => {
          this.loadingRegister = false
          this.credentials.payment_method = paymentMethod
        })
        .catch(() => {
          this.loadingRegister = false
        })
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    async handleRegister() {
      if (this.$store.getters['auth/isLoggedIn'])
      {
        Vue.$toast.open({
          message: 'You are already logged in. Please logout and try again!',
          type: 'default',
          duration: 5000,
        })
        return;
      }
      this.loadingRegister = true
      this.$store
          .dispatch('auth/registerAgency', this.credentials)
          .then((resp) => {
            this.credentials.user_id = resp.data.data.owner_id
            this.loadingRegister = false;
            if(resp.data.data.on_trial){
              this.currentStep += 2;
            }else {
              this.nextStep()
            }
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.orderForm.setErrors(err.response.data.errors)
            }
            this.loadingRegister = false
          })
    },
    async handlePayment() {
      await this.validateCard()
      if (!this.credentials.payment_method) return;
      this.loadingRegister = true
      this.$store
          .dispatch('auth/payAgency', this.credentials)
          .then((res) => {
            this.loadingRegister = false
            this.nextStep()
            // eslint-disable-next-line no-undef
            const tid = this.getCookie("_fprom_tid");
            const ref = this.getCookie("_fprom_ref");
            if(tid !== '' || ref !== '') {
              axios.post("https://firstpromoter.com/api/v1/track/signup", {
                email: res.data.data.user_email,
                tid:tid,
                ref_id:ref,
              },{
                headers: {
                  'x-api-key': '3ec43046a717fb4e9b4915e15f969ac6'
                }
              })
            }
            if(this.currentStep === 3){
              // Refresh for TapEngine Menu
              this.$store
                  .dispatch('auth/getProfile')
                  .then((user) => {

                  })
                  .catch(() => {
                    this.loadingImpersonate = false
                  })
            }
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.orderForm.setErrors(err.response.data.errors)
            }
            this.loadingRegister = false
          })
    }
  },
}
</script>

<style lang="scss" scoped>
.order-form {
  padding: 10px 40px 0 40px;
}
.order-details {
  padding: 30px;
  background: #ffffe9;
  ul {
    padding: 0;
    margin-top: 30px;
    list-style: none;
    li {
      position: relative;
      padding-bottom: 15px;
      padding-left: 25px;
      .feather {
        position: absolute;
        top: 2px;
        left: 0;
        color: #0ca90c;
      }
    }
  }
}
.order-page {
  .box-upgrade {
    margin-bottom: 30px;
    .btn {
      max-width: 100%;
      padding: 15px 100px;
    }
  }
  .secure-images img {
    width: 300px;
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}
.btn-primary{
  background: #1e3260;
  font-size: 1.25rem;
}
.card-header{
  background: #1e3260;
  padding: 20px 0;
}
@media screen and (max-width:767px){
  .card{
    border-radius: 0;
    .card-header{
      border-radius: 0;
    }
  }
  .container{
    padding: 0;
    overflow-x: hidden;
  }
  .order-form {
    padding: 5px 30px 0 30px;
  }
  .authentication-form{
    .form-control-label{
      display: none;
    }
  }
}
</style>
<style lang="scss">
@media screen and (max-width:767px){
  .authentication-form{
    .input-group-text{
      display: none;
    }
    .invalid-feedback {
      margin-left: 0 !important;
    }
    .form-control-label.text-white{
      color: #4b4b5a !important;
      display: block !important;
    }
  }
  .card-form{
    label[for="card-number-element"]{
      display: none;
    }
  }
}
</style>
